import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Box,
    CircularProgress,
    Divider,
    useTheme,
    useMediaQuery,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Slide,
    Link,
    useScrollTrigger,
} from "@mui/material";
import { Visibility, ThumbUp, Comment } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { formatKNumber, formatSimpleNumber, formatDateToKST } from "../../utils/formatters";
import HighlightedText from "../../components/HighlightedText";
import { subDays } from "date-fns";
import koLocale from "date-fns/locale/ko";
import StyledTableHeader from "../../components/StyledTableHeader";
import SearchInput from "../../components/SearchInput";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import { useLinkProps } from "../../utils/linkUtils";

function PastYoutube() {
    const yesterday = subDays(new Date(), 1);
    const [selectedDate, setSelectedDate] = useState(yesterday);
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchVisible, setIsSearchVisible] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const observer = useRef();
    const lastScrollTop = useRef(0);
    const searchBarRef = useRef(null);
    const linkProps = useLinkProps()();

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const scrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const lastVideoElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const fetchVideos = useCallback(async () => {
        try {
            setLoading(true);
            const formattedDate = selectedDate.toISOString().split("T")[0];
            const response = await axios.get(`/api/v1/youtube/past-youtube?page=${page}&limit=100&search=${searchTerm}&date=${formattedDate}`);
            setVideos((prevVideos) => (page === 1 ? response.data.data : [...prevVideos, ...response.data.data]));
            setHasMore(response.data.data.length > 0);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching past youtube videos:", err);
            setError("데이터를 불러오는 데 실패했습니다.");
            setLoading(false);
        }
    }, [page, searchTerm, selectedDate]);

    useEffect(() => {
        setPage(1);
    }, [searchTerm, selectedDate]);

    useEffect(() => {
        fetchVideos();
    }, [fetchVideos, page, searchTerm, selectedDate]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollDelta = scrollTop - lastScrollTop.current;
            const searchBarHeight = searchBarRef.current ? searchBarRef.current.offsetHeight : 0;

            if (scrollTop > searchBarHeight) {
                if (scrollDelta > 10) {
                    setIsSearchVisible(false);
                } else if (scrollDelta < -10) {
                    setIsSearchVisible(true);
                }
            } else {
                setIsSearchVisible(true);
            }

            lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleSearch = useCallback(
        (newSearchTerm) => {
            setSearchTerm(newSearchTerm);
            setPage(1);
            scrollToTop();
        },
        [scrollToTop]
    );

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
        setPage(1);
        scrollToTop();
    };

    const tableHeaders = [
        { label: "썸네일" },
        { label: "제목", sx: { minWidth: 250 } },
        { label: "채널" },
        { label: "조회수", align: "right" },
        { label: "좋아요", align: "right" },
        { label: "댓글", align: "right" },
        { label: "업로드", align: "right" },
    ];

    const renderTableView = () => (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="과거 인기 유튜브 동영상">
                <StyledTableHeader headers={tableHeaders} />
                <TableBody>
                    {videos.map((video, index) => (
                        <TableRow key={`${video.video_id}-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} ref={index === videos.length - 1 ? lastVideoElementRef : null}>
                            <TableCell>
                                <img src={video.video_thumbnails_uri} alt={video.video_title} style={{ width: 120, height: 68 }} />
                            </TableCell>
                            <TableCell sx={{ minWidth: 250 }}>
                                <Link
                                    href={`https://www.youtube.com/watch?v=${video.video_id}`}
                                    {...linkProps}
                                    sx={{
                                        color: "inherit",
                                        textDecoration: "none",
                                        "&:hover": {
                                            textDecoration: "underline",
                                        },
                                    }}
                                >
                                    <HighlightedText text={video.video_title} highlight={searchTerm} variant="body2" />
                                </Link>
                            </TableCell>
                            <TableCell>{video.channel_title}</TableCell>
                            <TableCell align="right">{formatSimpleNumber(video.video_view_count)}</TableCell>
                            <TableCell align="right">{formatSimpleNumber(video.video_like_count)}</TableCell>
                            <TableCell align="right">{formatKNumber(video.video_comment_count)}</TableCell>
                            <TableCell align="right">{formatDateToKST(video.video_publish_datetime, "MM-DD HH:mm")}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderCardView = () => (
        <Grid container spacing={2}>
            {videos.map((video, index) => (
                <Grid item xs={12} sm={6} md={4} key={`${video.video_id}-${index}`} ref={index === videos.length - 1 ? lastVideoElementRef : null}>
                    <Card elevation={3} sx={{ height: "100%" }}>
                        <CardActionArea href={`https://www.youtube.com/watch?v=${video.video_id}`} {...linkProps} sx={{ height: "100%" }}>
                            <CardMedia component="img" image={video.video_thumbnails_uri} alt={video.video_title} sx={{ height: 180 }} />
                            <CardContent sx={{ p: 2 }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        mb: 1,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        lineHeight: "1.3em",
                                        height: "2.8em",
                                    }}
                                >
                                    <HighlightedText text={video.video_title} highlight={searchTerm} />
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                    {video.channel_title}
                                </Typography>
                                <Box sx={{ mt: "auto" }}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box display="flex" alignItems="center">
                                            <Visibility fontSize="small" color="action" />
                                            <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                                {formatKNumber(video.video_view_count)}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <ThumbUp fontSize="small" color="action" />
                                            <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                                {formatKNumber(video.video_like_count)}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <Comment fontSize="small" color="action" />
                                            <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                                {formatKNumber(video.video_comment_count)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography variant="caption" color="text.secondary" display="block" mt={1}>
                                        {formatDateToKST(video.video_publish_datetime)}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: isMobile ? 2 : 4 }}>
            <Box mb={isMobile ? 2 : 4}>
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" fontWeight="bold" color="primary">
                    지난 유튜브
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                    일자별 인기 유튜브 동영상
                </Typography>
                <Divider sx={{ mb: isMobile ? 2 : 4 }} />

                <Slide appear={false} direction="down" in={isSearchVisible}>
                    <Box
                        ref={searchBarRef}
                        sx={{
                            mb: 3,
                            display: "flex",
                            justifyContent: "center",
                            position: "sticky",
                            top: 64,
                            zIndex: 1,
                            backgroundColor: "transparent",
                            padding: "10px 0",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", maxWidth: "600px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={koLocale}>
                                <DatePicker
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    format="yyyy-MM-dd"
                                    slotProps={{
                                        textField: {
                                            sx: {
                                                mr: 2,
                                                flexGrow: 1,
                                                "& .MuiOutlinedInput-root": {
                                                    backgroundColor: theme.palette.background.paper,
                                                    backdropFilter: "blur(10px)",
                                                },
                                            },
                                        },
                                        actionBar: {
                                            actions: ["today"],
                                        },
                                    }}
                                    localeText={{ todayButtonLabel: "오늘" }}
                                />
                            </LocalizationProvider>
                            <SearchInput onSearch={handleSearch} theme={theme} placeholder="동영상 검색..." initialValue={searchTerm} />
                        </Box>
                    </Box>
                </Slide>

                {isMobile ? renderCardView() : renderTableView()}
                {loading && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
            <ScrollToTopButton />
        </Container>
    );
}

export default PastYoutube;
