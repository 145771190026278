import React, { createContext, useState, useEffect, useContext } from "react";
import { syncSettingsWithApp, getCurrentSettings, sendMessageToApp } from "../utils/platformUtils";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({
        darkMode: false,
        newWindow: false,
        sidebarExpanded: true,
        pushTopics: {},
    });

    useEffect(() => {
        const savedSettings = getCurrentSettings();
        if (Object.keys(savedSettings).length > 0) {
            setSettings(savedSettings);
        }

        // 앱에서 구독 정보 동기화를 위한 함수
        window.syncPushTopics = (topics) => {
            setSettings((prevSettings) => ({
                ...prevSettings,
                pushTopics: topics.reduce((acc, topic) => ({ ...acc, [topic]: true }), {}),
            }));
        };

        // 앱에서 설정 동기화를 위한 함수
        window.syncSettings = (appSettings) => {
            setSettings((prevSettings) => ({
                ...prevSettings,
                ...appSettings,
            }));
        };

        // 앱에 저장된 구독 정보 요청
        sendMessageToApp("getSubscribedTopics", {});

        // 구독 결과 처리를 위한 함수
        window.handlePushSubscriptionResult = (result) => {
            if (result.success) {
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    pushTopics: {
                        ...prevSettings.pushTopics,
                        [result.topic]: result.action === "subscribe",
                    },
                }));
            }
        };
    }, []);

    const updateSetting = (key, value) => {
        const newSettings = { ...settings, [key]: value };
        setSettings(newSettings);
        localStorage.setItem("appSettings", JSON.stringify(newSettings));

        // 앱의 설정 동기화 함수 호출
        syncSettingsWithApp(newSettings);
    };

    const updatePushTopic = (topic, isSubscribed) => {
        const action = isSubscribed ? "subscribe" : "unsubscribe";
        sendMessageToApp("pushSubscription", { action, topic });
    };

    return <SettingsContext.Provider value={{ settings, updateSetting, updatePushTopic }}>{children}</SettingsContext.Provider>;
};

export const useSettings = () => useContext(SettingsContext);
