import React, { useEffect } from "react";
import { detectApp } from "../utils/platformUtils";

function AdSenseScript() {
    useEffect(() => {
        const app = detectApp();

        if (app === "Web" && process.env.REACT_APP_ADSENSE_SRC) {
            const script = document.createElement("script");
            script.src = process.env.REACT_APP_ADSENSE_SRC;
            script.async = true;
            script.crossOrigin = "anonymous";
            document.head.appendChild(script);
        }
    }, []);

    return null;
}

export default AdSenseScript;
