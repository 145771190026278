import React, { useState, useEffect } from "react";

const PlatformTest = () => {
    const [userAgent, setUserAgent] = useState("");
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        setUserAgent(navigator.userAgent);
    }, [refreshKey]);

    // 웹 환경인지 체크 (iOS/Android 앱이 아닌 경우에만 true)
    const isWeb = () => {
        return !(window.webkit?.messageHandlers || window.Android);
    };

    // 모바일 기기 체크
    const checkMobile = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    };

    const handleRefresh = () => {
        setRefreshKey((prev) => prev + 1);
    };

    const ResultRow = ({ label, value }) => (
        <div className="flex items-center p-4 border-b border-gray-200">
            <div className="flex-1">
                <div className="font-medium">{label}</div>
                <div className="text-sm text-gray-600">{value.toString()}</div>
            </div>
            <div className="ml-4">{value ? "✅" : "❌"}</div>
        </div>
    );

    return (
        <div className="max-w-2xl mx-auto p-4 bg-white rounded-lg shadow">
            <div className="mb-6">
                <h1 className="text-2xl font-bold mb-2">플랫폼 감지 테스트</h1>
                <button onClick={handleRefresh} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mb-4">
                    새로고침
                </button>
            </div>

            <div className="mb-6 p-4 bg-gray-50 rounded">
                <h2 className="text-lg font-semibold mb-2">User Agent 문자열</h2>
                <div className="break-all text-sm text-gray-600">{userAgent}</div>
            </div>

            <div className="space-y-2">
                <ResultRow label="웹 환경 (isWeb)" value={isWeb()} />
                <ResultRow label="모바일 기기 (checkMobile)" value={checkMobile()} />
                <ResultRow label="iOS 앱 (webkit)" value={!!window.webkit?.messageHandlers} />
                <ResultRow label="Android 앱 (Android)" value={!!window.Android} />
            </div>

            <div className="mt-6 p-4 bg-gray-50 rounded">
                <h2 className="text-lg font-semibold mb-2">결론</h2>
                <div className="text-gray-700">
                    {checkMobile() ? "모바일" : "데스크톱"} 환경의 {isWeb() ? "웹 브라우저" : "앱"} 에서 실행 중입니다.
                    {!isWeb() && <div className="mt-1 text-sm">구체적으로는 {window.webkit?.messageHandlers ? "iOS" : "Android"} 앱에서 실행 중입니다.</div>}
                </div>
            </div>

            <div className="mt-6 p-4 bg-yellow-50 rounded">
                <h2 className="text-lg font-semibold mb-2">앱 다운로드 공지 표시 여부</h2>
                <div className="text-gray-700">{isWeb() && checkMobile() ? "✅ 앱 다운로드 공지가 표시됩니다." : "❌ 앱 다운로드 공지가 표시되지 않습니다."}</div>
                <div className="mt-2 text-sm text-gray-600">
                    {!isWeb()
                        ? "이미 앱에서 실행 중이므로 공지가 표시되지 않습니다."
                        : !checkMobile()
                        ? "모바일 기기가 아니므로 공지가 표시되지 않습니다."
                        : "모바일 웹 브라우저이므로 공지가 표시됩니다."}
                </div>
            </div>
        </div>
    );
};

export default PlatformTest;
