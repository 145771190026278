export function detectPlatform() {
    if (window.webkit && window.webkit.messageHandlers) {
        return "iOS";
    } else if (window.Android) {
        return "Android";
    }
    return "Web";
}

export function detectApp() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes("oneulbest_aos")) {
        return "APP_AOS";
    } else if (userAgent.includes("oneulbest_ios")) {
        return "APP_IOS";
    } else {
        return "Web";
    }
}

export function syncSettingsWithApp(settings) {
    const platform = detectPlatform();
    switch (platform) {
        case "iOS":
            window.webkit.messageHandlers.syncSettings.postMessage(JSON.stringify(settings));
            break;
        case "Android":
            window.Android.syncSettings(JSON.stringify(settings));
            break;
        case "Web":
            // 웹에서는 별도의 동작 필요 없음
            break;
    }
}

export function getCurrentSettings() {
    // 이 함수는 웹에서 직접 설정을 반환하고, 앱에서는 오버라이드될 것입니다.
    return JSON.parse(localStorage.getItem("appSettings")) || {};
}

export function sendMessageToApp(handler, message) {
    const platform = detectPlatform();
    switch (platform) {
        case "iOS":
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[handler]) {
                window.webkit.messageHandlers[handler].postMessage(message);
            }
            break;
        case "Android":
            if (window.Android && typeof window.Android[handler] === "function") {
                window.Android[handler](JSON.stringify(message));
            }
            break;
        case "Web":
            console.log(`Web message (${handler}):`, message);
            break;
    }
}

export function log(...args) {
    console.log(...args); // 기본 콘솔 로깅

    // 네이티브 로깅 (iOS)
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.log) {
        window.webkit.messageHandlers.log.postMessage(args.join(" "));
    }

    // 네이티브 로깅 (Android)
    if (window.Android && typeof window.Android.log === "function") {
        window.Android.log(args.join(" "));
    }
}
