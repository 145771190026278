import React from "react";
import { Container, Typography, Box, List, ListItem } from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                개인정보 처리방침
            </Typography>
            <Box component="article">
                <Typography paragraph>
                    &lt;(주)오늘베스트&gt;('https://oneulbest.com'이하 '오늘베스트') 은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을
                    원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
                </Typography>
                <Typography paragraph>&lt;(주)오늘베스트&gt;('오늘베스트') 은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</Typography>
                <Typography paragraph>○ 본 방침은부터 2020년 1월 1일부터 시행됩니다.</Typography>

                <Typography variant="h6" gutterBottom>
                    1. 개인정보의 처리 목적
                </Typography>
                <Typography paragraph>
                    &lt;(주)오늘베스트&gt;('https://oneulbest.com'이하 '오늘베스트') 은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지
                    않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
                </Typography>
                <List>
                    <ListItem>가. 홈페이지 회원가입 및 관리</ListItem>
                    <ListItem>나. 민원사무 처리</ListItem>
                    <ListItem>다. 재화 또는 서비스 제공</ListItem>
                    <ListItem>라. 마케팅 및 광고에의 활용</ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    2. 개인정보의 처리 및 보유 기간
                </Typography>
                <Typography paragraph>
                    ① &lt;(주)오늘베스트&gt;('오늘베스트') 은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를
                    처리,보유합니다.
                </Typography>
                <Typography paragraph>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</Typography>

                <Typography variant="h6" gutterBottom>
                    3. 정보주체와 법정대리인의 권리·의무 및 그 행사방법
                </Typography>
                <Typography paragraph>이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</Typography>
                <List>
                    <ListItem>① 정보주체는 (주)오늘베스트에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.</ListItem>
                    <ListItem>
                        ② 제1항에 따른 권리 행사는(주)오늘베스트에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주)오늘베스트은(는) 이에
                        대해 지체 없이 조치하겠습니다.
                    </ListItem>
                    <ListItem>
                        ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을
                        제출하셔야 합니다.
                    </ListItem>
                    <ListItem>④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</ListItem>
                    <ListItem>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</ListItem>
                    <ListItem>
                        ⑥ (주)오늘베스트은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    4. 개인정보의 파기
                </Typography>
                <Typography paragraph>
                    &lt;(주)오늘베스트&gt;('오늘베스트') 은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
                </Typography>
                <List>
                    <ListItem>
                        -파기절차
                        <br />
                        이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진
                        개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                    </ListItem>
                    <ListItem>
                        -파기기한
                        <br />
                        이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가
                        불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
                    </ListItem>
                    <ListItem>
                        -파기방법
                        <br />
                        전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    5. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항
                </Typography>
                <Typography paragraph>① (주)오늘베스트 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠기(cookie)'를 사용합니다.</Typography>
                <Typography paragraph>
                    ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                </Typography>
                <List>
                    <ListItem>
                        가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
                        사용됩니다.
                    </ListItem>
                    <ListItem>나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</ListItem>
                    <ListItem>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    6. 개인정보 보호책임자 작성
                </Typography>
                <Typography paragraph>
                    ① (주)오늘베스트('https://oneulbest.com'이하 '오늘베스트) 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을
                    위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                </Typography>
                <List>
                    <ListItem>
                        ▶ 개인정보 보호책임자
                        <br />
                        성명 :개인정보책임자
                        <br />
                        직책 :매니저
                        <br />
                        직급 :매니저
                        <br />
                        연락처 : todaybeststory-privacy@gmail.com,
                        <br />※ 개인정보 보호 담당부서로 연결됩니다.
                    </ListItem>
                    <ListItem>
                        ▶ 개인정보 보호 담당부서
                        <br />
                        부서명 :정보보안부
                        <br />
                        담당자 :보안담당자
                        <br />
                        연락처 : todaybeststory-privacy@gmail.com,
                    </ListItem>
                </List>
                <Typography paragraph>
                    ② 정보주체께서는 (주)오늘베스트('https://oneulbest.com'이하 '오늘베스트) 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에
                    관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)오늘베스트('https://oneulbest.com'이하 '오늘베스트) 은(는) 정보주체의 문의에 대해 지체 없이 답변 및
                    처리해드릴 것입니다.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    7. 개인정보 처리방침 변경
                </Typography>
                <Typography paragraph>
                    ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    8. 개인정보의 안전성 확보 조치
                </Typography>
                <Typography paragraph>
                    &lt;(주)오늘베스트&gt;('오늘베스트') 은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
                </Typography>
                <List>
                    <ListItem>1) 정기적인 자체 감사 실시</ListItem>
                    <ListItem>2) 개인정보 취급 직원의 최소화 및 교육</ListItem>
                    <ListItem>3) 내부관리계획의 수립 및 시행</ListItem>
                    <ListItem>4) 해킹 등에 대비한 기술적 대책</ListItem>
                    <ListItem>5) 개인정보의 암호화</ListItem>
                    <ListItem>6) 접속기록의 보관 및 위변조 방지</ListItem>
                    <ListItem>7) 개인정보에 대한 접근 제한</ListItem>
                    <ListItem>8) 문서보안을 위한 잠금장치 사용</ListItem>
                    <ListItem>9) 비인가자에 대한 출입 통제</ListItem>
                </List>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
