import React, { useState } from "react";
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Switch, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Checkbox } from "@mui/material";
import { Settings, Brightness4, OpenInNew, MenuOpen, Notifications } from "@mui/icons-material";
import { useSettings } from "../contexts/SettingsContext";
import { detectPlatform, detectApp, log } from "../utils/platformUtils";

function SettingsMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPushDialog, setOpenPushDialog] = useState(false);
    const { settings, updateSetting, updatePushTopic } = useSettings();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const platform = detectApp();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePushDialogOpen = () => {
        setOpenPushDialog(true);
        handleClose();
    };

    const handlePushDialogClose = () => {
        setOpenPushDialog(false);
    };

    const pushTopics = [
        { key: "community_realtime", label: "커뮤니티 실시간 베스트" },
        { key: "community_daily", label: "커뮤니티 일간 베스트" },
        { key: "community_monthly_ranking", label: "커뮤니티 월간 랭킹" },
        { key: "youtube_daily", label: "유튜브 일간 베스트" },
        { key: "shopping_daily", label: "쇼핑 일간 베스트" },
    ];

    const handlePushTopicChange = (topic) => {
        const newState = !settings.pushTopics[topic];
        updatePushTopic(topic, newState);
    };

    return (
        <>
            <IconButton onClick={handleClick} color="inherit">
                <Settings />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <Brightness4 fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>화면 모드 (밝게/어둡게)</ListItemText>
                    <Switch edge="end" checked={settings.darkMode} onChange={(e) => updateSetting("darkMode", e.target.checked)} />
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <OpenInNew fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>새 창에서 링크 열기</ListItemText>
                    <Switch edge="end" checked={settings.newWindow} onChange={(e) => updateSetting("newWindow", e.target.checked)} />
                </MenuItem>
                {!isMobile && (
                    <MenuItem>
                        <ListItemIcon>
                            <MenuOpen fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>사이드바 메뉴 펼치기</ListItemText>
                        <Switch edge="end" checked={settings.sidebarExpanded} onChange={(e) => updateSetting("sidebarExpanded", e.target.checked)} />
                    </MenuItem>
                )}
                {(platform === "APP_AOS" || platform === "APP_IOS") && (
                    <MenuItem onClick={handlePushDialogOpen}>
                        <ListItemIcon>
                            <Notifications fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>푸시 알림 설정</ListItemText>
                    </MenuItem>
                )}
            </Menu>
            <Dialog open={openPushDialog} onClose={handlePushDialogClose}>
                <DialogTitle>푸시 알림 설정</DialogTitle>
                <DialogContent>
                    <List>
                        {pushTopics.map((topic) => (
                            <ListItem key={topic.key}>
                                <ListItemText primary={topic.label} />
                                <Checkbox edge="end" onChange={() => handlePushTopicChange(topic.key)} checked={settings.pushTopics?.[topic.key] || false} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePushDialogClose}>닫기</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SettingsMenu;
