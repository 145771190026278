import React, { useState, useEffect } from "react";
import { Fab, Zoom } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import { alpha } from "@mui/material/styles";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <Zoom in={isVisible}>
            <Fab
                color="primary"
                size="small"
                onClick={scrollToTop}
                sx={(theme) => ({
                    position: "fixed",
                    bottom: "55px",
                    right: "20px",
                    zIndex: 1000,
                    backgroundColor: alpha(theme.palette.primary.main, 0.6), // 80% 불투명도
                    "&:hover": {
                        backgroundColor: alpha(theme.palette.primary.main, 0.9), // 호버 시 90% 불투명도
                    },
                })}
            >
                <KeyboardArrowUp />
            </Fab>
        </Zoom>
    );
};

export default ScrollToTopButton;
