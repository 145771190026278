import React, { useState, useEffect } from "react";
import {
    Container,
    Divider,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Box,
    CircularProgress,
    useTheme,
    useMediaQuery,
    Chip,
    Avatar,
    Card,
    CardContent,
    Grid,
} from "@mui/material";
import RankingTrendChart from "../../components/RankingTrendChart";
import { Visibility, ThumbUp, Comment, Description, TrendingUp } from "@mui/icons-material";
import axios from "axios";
import { formatKNumber, formatSimpleNumber, formatPercentage } from "../../utils/formatters";
import StyledTableHeader from "../../components/StyledTableHeader";
import ScrollToTopButton from "../../components/ScrollToTopButton";

function MonthlyRanking() {
    const [ranking, setRanking] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const fetchRanking = async () => {
            try {
                const response = await axios.get("/api/v1/community/monthly-ranking");
                setRanking(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching monthly ranking:", err);
                setError("데이터를 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchRanking();
    }, []);

    const formatMinutesSeconds = (seconds) => {
        if (typeof seconds !== "number") return "00:00";
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    const tableHeaders = [
        { label: "순위" },
        { label: "커뮤니티" },
        { label: "순위 추이", align: "center", sx: { minWidth: 120 } },
        { label: "방문수", align: "right" },
        { label: "평균 방문 시간(분)", align: "right" },
        { label: "이탈률", align: "right" },
        { label: "PPV", align: "right" },
        { label: "게시물 수", align: "right" },
        { label: "조회수", align: "right" },
        { label: "추천수", align: "right" },
        { label: "댓글수", align: "right" },
    ];

    const currentMonth = ranking.length > 0 && ranking[0].month ? ranking[0].month.split("-")[1] : "";

    const renderDesktopView = () => (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="monthly community ranking table">
                <StyledTableHeader headers={tableHeaders} />
                <TableBody>
                    {ranking.map((community) => (
                        <TableRow key={community.community_id}>
                            <TableCell component="th" scope="row">
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                    <TrendingUp fontSize="small" sx={{ mr: 0.5 }} />
                                    <Typography variant="body2">{community.ranking}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Chip
                                    avatar={
                                        <Avatar
                                            src={`/images/community-icons/${community.community_id.toLowerCase()}.png`}
                                            sx={{
                                                "& img": {
                                                    objectPosition: "left",
                                                },
                                            }}
                                        />
                                    }
                                    label={community.community_name}
                                    size="small"
                                />
                            </TableCell>
                            <TableCell sx={{ minWidth: 120 }}>
                                <RankingTrendChart trendData={community.ranking_trend} />
                            </TableCell>
                            <TableCell align="right">{formatSimpleNumber(community.total_visits)}</TableCell>
                            <TableCell align="right">{formatMinutesSeconds(community.avg_visit_duration)}</TableCell>
                            <TableCell align="right">{formatPercentage(community.bounce_rate)}</TableCell>
                            <TableCell align="right">{community.ppv}</TableCell>
                            <TableCell align="right">{formatKNumber(community.post_total)}</TableCell>
                            <TableCell align="right">{formatSimpleNumber(community.read_total)}</TableCell>
                            <TableCell align="right">{formatSimpleNumber(community.upvote_total)}</TableCell>
                            <TableCell align="right">{formatSimpleNumber(community.comment_total)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderMobileView = () => (
        <Grid container spacing={2}>
            {ranking.map((community) => (
                <Grid item xs={12} key={community.community_id}>
                    <Card elevation={3}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Chip
                                    avatar={
                                        <Avatar
                                            src={`/images/community-icons/${community.community_id.toLowerCase()}.png`}
                                            sx={{
                                                "& img": {
                                                    objectPosition: "left",
                                                },
                                            }}
                                        />
                                    }
                                    label={community.community_name}
                                    size="small"
                                />
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <TrendingUp fontSize="small" sx={{ mr: 0.5 }} />
                                    <Typography variant="h6" component="span">
                                        {community.ranking}위
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="body2" color="text.secondary">
                                    방문수: {formatKNumber(community.total_visits)}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    평균 방문 시간: {formatMinutesSeconds(community.avg_visit_duration)}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="body2" color="text.secondary">
                                    이탈률: {formatPercentage(community.bounce_rate)}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    PPV: {community.ppv}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                                <Box display="flex" alignItems="center">
                                    <Visibility fontSize="small" color="action" />
                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                        {formatKNumber(community.read_total)}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <ThumbUp fontSize="small" color="action" />
                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                        {formatKNumber(community.upvote_total)}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Comment fontSize="small" color="action" />
                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                        {formatKNumber(community.comment_total)}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Description fontSize="small" color="action" />
                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                        {formatKNumber(community.post_total)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box mt={1}>
                                <RankingTrendChart trendData={community.ranking_trend} />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <Container maxWidth="lg" sx={{ py: isMobile ? 2 : 4 }}>
            <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" fontWeight="bold" color="primary">
                {currentMonth ? `${currentMonth}월 커뮤니티 순위` : "월 커뮤니티 순위"}
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                전월 커뮤니티 소비 데이터 기준으로 선정
            </Typography>
            <Divider sx={{ mb: isMobile ? 2 : 4 }} />
            {isMobile ? renderMobileView() : renderDesktopView()}
            <ScrollToTopButton />
        </Container>
    );
}

export default MonthlyRanking;
