import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Container, Typography, Grid, Card, CardContent, CardActionArea, Box, CircularProgress, Chip, Avatar, Divider, useTheme, useMediaQuery, Slide } from "@mui/material";
import { Visibility, ThumbUp, Comment } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { formatNumber, formatDate } from "../../utils/formatters";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import { useLinkProps } from "../../utils/linkUtils";
import SearchInput from "../../components/SearchInput";
import { ko } from "date-fns/locale";

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function BestOfBest() {
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const linkProps = useLinkProps()();
    const lastScrollTop = useRef(0);
    const searchBarRef = useRef(null);

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSearchVisible, setIsSearchVisible] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    // URL에서 날짜 파라미터를 읽어옵니다.
    const urlParams = new URLSearchParams(location.search);
    const urlDate = urlParams.get("date");
    const initialDate = urlDate ? new Date(urlDate) : new Date();
    const [selectedDate, setSelectedDate] = useState(initialDate);

    const fetchBestPosts = useCallback(async () => {
        try {
            setLoading(true);
            const formattedDate = selectedDate.toISOString().split("T")[0];
            const response = await axios.get(`/api/v1/community/best-of-best?date=${formattedDate}&search=${searchTerm}`);
            setPosts(shuffleArray(response.data.data));
            setLoading(false);
        } catch (err) {
            console.error("Error fetching best posts:", err);
            setError("데이터를 불러오는 데 실패했습니다.");
            setLoading(false);
        }
    }, [selectedDate, searchTerm]);

    useEffect(() => {
        fetchBestPosts();
    }, [fetchBestPosts]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollDelta = scrollTop - lastScrollTop.current;
            const searchBarHeight = searchBarRef.current ? searchBarRef.current.offsetHeight : 0;

            if (scrollTop > searchBarHeight) {
                if (scrollDelta > 10) {
                    setIsSearchVisible(false);
                } else if (scrollDelta < -10) {
                    setIsSearchVisible(true);
                }
            } else {
                setIsSearchVisible(true);
            }

            lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleSearch = useCallback((newSearchTerm) => {
        setSearchTerm(newSearchTerm);
    }, []);

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: isMobile ? 2 : 4 }}>
            <Box mb={isMobile ? 2 : 4}>
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" fontWeight="bold" color="primary">
                    베스트 오브 베스트
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                    각 커뮤니티의 최고 인기 게시물
                </Typography>
                <Divider sx={{ mb: isMobile ? 2 : 4 }} />

                <Slide appear={false} direction="down" in={isSearchVisible}>
                    <Box
                        ref={searchBarRef}
                        sx={{
                            mb: 3,
                            display: "flex",
                            justifyContent: "center",
                            position: "sticky",
                            top: 64,
                            zIndex: 1,
                            backgroundColor: "transparent",
                            padding: "10px 0",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", maxWidth: "600px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
                                <DatePicker
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    format="yyyy-MM-dd"
                                    slotProps={{
                                        textField: {
                                            sx: {
                                                mr: 2,
                                                flexGrow: 1,
                                                "& .MuiOutlinedInput-root": {
                                                    backgroundColor: theme.palette.background.paper,
                                                    backdropFilter: "blur(10px)",
                                                },
                                            },
                                        },
                                        actionBar: {
                                            actions: ["today"],
                                        },
                                    }}
                                    localeText={{ todayButtonLabel: "오늘" }}
                                />
                            </LocalizationProvider>
                            <SearchInput onSearch={handleSearch} theme={theme} placeholder="게시물 검색..." initialValue={searchTerm} />
                        </Box>
                    </Box>
                </Slide>

                <Grid container spacing={isMobile ? 2 : 3}>
                    {posts.map((post) => (
                        <Grid item xs={12} sm={6} md={4} key={post.post_id}>
                            <Card
                                elevation={3}
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                                    "&:hover": {
                                        transform: "translateY(-5px)",
                                        boxShadow: theme.shadows[10],
                                    },
                                }}
                            >
                                <CardActionArea href={post.post_url} {...linkProps} sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                    <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column", p: isMobile ? 2 : 3 }}>
                                        <Chip
                                            avatar={
                                                <Avatar
                                                    src={`/images/community-icons/${post.community_id.toLowerCase()}.png`}
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        "& img": {
                                                            objectPosition: "left",
                                                        },
                                                    }}
                                                />
                                            }
                                            label={post.community_name}
                                            sx={{
                                                alignSelf: "flex-start",
                                                mb: 1,
                                                height: "auto",
                                                "& .MuiChip-label": {
                                                    display: "block",
                                                    whiteSpace: "normal",
                                                    wordBreak: "break-word",
                                                    lineHeight: 1.2,
                                                    padding: "4px 8px",
                                                },
                                            }}
                                        />
                                        <Typography gutterBottom variant={isMobile ? "subtitle1" : "h6"} component="h2" sx={{ fontWeight: "medium", mb: 2 }}>
                                            {post.post_title}
                                        </Typography>
                                        <Box sx={{ mt: "auto" }}>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                <Box display="flex" alignItems="center">
                                                    <Visibility fontSize="small" color="action" />
                                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                                        {formatNumber(post.read_count)}
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    <ThumbUp fontSize="small" color="action" />
                                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                                        {formatNumber(post.upvote_count)}
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    <Comment fontSize="small" color="action" />
                                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                                        {formatNumber(post.comment_count)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography variant="caption" color="text.secondary" display="block" textAlign="right">
                                                {formatDate(post.post_datetime)}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <ScrollToTopButton />
        </Container>
    );
}

export default BestOfBest;
