import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography, Box, Link, IconButton } from "@mui/material";
import { Close as CloseIcon, Android as AndroidIcon, Apple as AppleIcon } from "@mui/icons-material";

const AppDownloadNotice = ({ enabled = true }) => {
    const [open, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // 웹 환경인지 체크 (iOS/Android 앱이 아닌 경우에만 true)
        const isWeb = () => {
            return !(window.webkit?.messageHandlers || window.Android);
        };

        // 모바일 기기 체크
        const checkMobile = () => {
            const userAgent = navigator.userAgent.toLowerCase();
            return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        };

        // hideUntil 시간이 지났는지 확인
        const checkHideExpiration = () => {
            const hideData = localStorage.getItem("hideAppDownloadNotice");
            if (!hideData) return true;

            try {
                const { hideUntil } = JSON.parse(hideData);
                return new Date().getTime() > hideUntil;
            } catch (e) {
                localStorage.removeItem("hideAppDownloadNotice");
                return true;
            }
        };

        // localStorage 체크, 모바일 여부, 웹 환경 확인
        const shouldShow = enabled && checkHideExpiration() && checkMobile();
        setIsMobile(checkMobile());
        setOpen(shouldShow);
    }, [enabled]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleHideForWeek = () => {
        // 7일 후의 timestamp 계산
        const hideUntil = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;
        localStorage.setItem("hideAppDownloadNotice", JSON.stringify({ hideUntil }));
        setOpen(false);
    };

    // enabled가 false이거나 모바일이 아닐 경우 아무것도 렌더링하지 않음
    if (!enabled || !isMobile) return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    p: 1,
                },
            }}
        >
            <DialogContent>
                <Typography variant="h6" gutterBottom align="center" sx={{ mt: 1 }}>
                    더 편리한 모바일 앱으로 이용하세요!
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                        mt: 3,
                        flexDirection: { xs: "column", sm: "row" },
                    }}
                >
                    <Link href="https://play.google.com/store/apps/details?id=com.oneulbest" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none" }}>
                        <Button
                            variant="contained"
                            startIcon={<AndroidIcon />}
                            fullWidth
                            sx={{
                                backgroundColor: "#3DDC84",
                                "&:hover": { backgroundColor: "#32B06C" },
                            }}
                        >
                            Play 스토어
                        </Button>
                    </Link>

                    <Link href="https://apps.apple.com/app/6708239460" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none" }}>
                        <Button
                            variant="contained"
                            startIcon={<AppleIcon />}
                            fullWidth
                            sx={{
                                backgroundColor: "#000000",
                                "&:hover": { backgroundColor: "#333333" },
                            }}
                        >
                            App Store
                        </Button>
                    </Link>
                </Box>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
                <Button onClick={handleHideForWeek} color="primary">
                    일주일 동안 보지 않기
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AppDownloadNotice;
